import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { CareerCard } from 'components/CareerCard'
import Img from 'gatsby-image'
import Layout from 'components/Layout'
import './../assets/stylesheets/pages/about.scss'
import { Helmet } from 'react-helmet'
import HHG from './../assets/images/hhg.png'

export default () => (
  <StaticQuery
    query={graphql`
      query dirk {
        dirk: file(relativePath: { eq: "dirk.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const getAge = (dateString) => {
        const today = new Date()
        const birthDate = new Date(dateString)
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--
        }
        return age
      }
      return (
        <Layout>
          <Helmet title="Dirk Mulder Portfolio | About Me">
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="This the portfolio of Dirk Mulder. On this page you learn more about me."
            />
            <meta name="keywords" content="Dirk, Mulder, About, Me" />
            <html lang="en" />
          </Helmet>

          <div className="picture"></div>
          <div className="imagecontainer aboutContainer">
            <div className="myimage">
              <Img
                fluid={data.dirk.childImageSharp.fluid}
                imgStyle={{ objectPosition: 'center right' }}
              />
            </div>
          </div>

          <div className="textblock aboutContainer">
            <div>
              <h1>About me</h1>
              <p>
                Hello everyone! Thanks for reading my portfolio. Let me
                introduce myself on this page. I am Dirk Mulder, at the moment I
                am {getAge('20052000')} years old.
              </p>
              <p>
                I grew up in rural Holland, where I quickly became fascinated by
                everything regarding tech. Since I was a small boy I liked to
                make websites and express myself with a website. Soon, I
                realized that this is not just something I like to do, but also
                something that can become my job. As I realized that the best
                jobs are the ones where you don&#39;t even know that you are
                working, I decided to study at the Mediacollege in Amsterdam on
                the fast track. Here, I became more familiar with interaction
                design.{' '}
              </p>
              <p>
                Subsequently, I was ready for my internship! My first internship
                was at BLUB media in Heerhugowaard, the Netherlands. I gained a
                lot of business skills here because the management was super
                transparent. I had a great time working here.
              </p>
              <p>
                After this, I was ready for a new step! An internship in
                Vietnam! During my teenage years/childhood, I had the chance to
                travel a lot. I had been to every continent before I was
                eighteen years old. Those experiences contributed to the fact
                that I really wanted to study abroad. After I heard about an
                internship in Vietnam I was convinced directly: I have to go
                there!{' '}
              </p>
              <p>
                The company where I would do my internship was called Digital
                Launch. Digital Launch provides marketing and web design
                services to many international clients. Here I gained a lot of
                skills in working in web development.{' '}
              </p>
              <p>
                When I was supposed to go back to the Netherlands after my
                internship at Digital Launch, I quickly realized that going back
                wasn&#39;t so easy due to the COVID-19 pandemic. Vietnam closed
                the borders for inbound travel while leaving the country is
                technically possible, it&#39;s very hard as there are zero to no
                international flights. This gave me the opportunity to stay a
                bit longer in Vietnam and I got the offer to help at Hello
                Health Group.
              </p>
              <p>
                Hello Health Group is a company that is democratizing health
                information in South East Asia. I am extremely proud that I can
                make websites and tools to improve the lives of millions of
                people while working at Hello Health Group. I am working here as
                a frontend developer creating websites that are viewed by
                millions on a daily basis.
              </p>
              <p>
                Thank you for reading this! If you have any questions, feel free
                to let me know on the <a href="/contact">contact page</a>.
              </p>
            </div>
            <div>
              <h3>Work Experience</h3>
              <CareerCard
                title="Frontend Developer"
                companyName="Frontend developer at Hello Health Group"
                description="Hello Health Group is a company that is democratising health information in South East Asia. I am extremely proud that I can make websites and tools to improve the lives of millions of people while working at Hello Health Group. I am working here as a frontend developer."
                city="Ho Chi Minh City"
                period="Nov 2020 - present"
                logo={
                  <img src="https://dirkapp.s3-ap-southeast-1.amazonaws.com/hhg.png" />
                }
              />
              <CareerCard
                title="Frontend Developer"
                companyName="Conceptual Studio"
                description="At Conceptual Studio I am working in managing several clients as a project manager and helping with my frontend and design experience as well. "
                city="Ho Chi Minh City"
                period="Nov 2020 - present"
                logo={
                  <img src="https://dirkapp.s3-ap-southeast-1.amazonaws.com/cs.jpeg" />
                }
              />
              <h3 style={{ marginTop: 54 }}>Internships</h3>
              <CareerCard
                title="Intern Frontend Development"
                companyName="Frontend Development at Digital Launch"
                city="Ho Chi Minh City"
                period="Feb 2020 - Jul 2020"
                logo={
                  <img
                    style={{ mixBlendMode: 'darken' }}
                    src="https://dirkapp.s3-ap-southeast-1.amazonaws.com/Logo.png"
                  />
                }
              />
              <CareerCard
                title="Intern design and development"
                companyName="Design and development at BLUB media"
                city="Heerhugowaard, the Netherlands"
                period="Feb 2020 - Jul 2020"
                logo={
                  <img src="https://dirkapp.s3-ap-southeast-1.amazonaws.com/blub.jpeg" />
                }
              />
              <h3 style={{ marginTop: 54 }}>Education</h3>
              <CareerCard
                title="Interaction Design at Mediacollege Amsterdam"
                companyName="Interaction Design at Mediacollege Amsterdam"
                city="Amsterdam, the Netherlands"
                period="Sept 2018 - Aug 2021"
                logo={
                  <img src="https://dirkapp.s3-ap-southeast-1.amazonaws.com/ma.jpg" />
                }
              />
            </div>
          </div>
        </Layout>
      )
    }}
  />
)
