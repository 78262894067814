import React from 'react'
import './../assets/stylesheets/components/careerCard.scss'

type Props = {
  logo: JSX.Element
  companyName: string
  description: string
  city: string
  period: string
}

const CareerCard = ({
  logo,
  companyName,
  description,
  city,
  period,
}: Props) => {
  return (
    <div
      className="careerCard"
      data-has-description={description ? true : undefined}
    >
      <div className="careerCard__Logo">{logo}</div>
      <div className="careerCard__Title">
        <h3>{companyName}</h3>
        <div
          className="careerCard__info"
          style={description ? { marginBottom: 12 } : undefined}
        >
          <h4>{city}</h4>
          <h4>{period}</h4>
        </div>
      </div>
      <div className="careerCard__description">
        <p>{description}</p>
      </div>
    </div>
  )
}

export { CareerCard }
